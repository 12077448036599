// PdfComponent.js
import React, { useEffect, useState } from "react";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import {
  collection,
  query as firestoreQuery,
  where,
  getDocs,
} from "firebase/firestore";
import { firestore } from "../firebase-config"; // Adjust import as needed
import MyDocument from "./MyDocument";
import { useLocation } from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const divStyles = {
  div: {
    width: "100%",
    height: "100%",
    overFlow: "hidden",
  },
  linkStyle: {
    backgroundColor: "#038d96",

    width: "40%",
    marginLeft: "40%",
    position: "relative",
    top: "20px",
    padding: "10px",
    borderRadius: "8px",
    color: "#fff",
    fontSize: "34px",
    textDecoration: "none",
    direction: "rtl",
    overFlow: "hidden",
  },
};

// PdfComponent.js

const PdfComponent = () => {
  const query = useQuery();
  const uuid = query.get("uuid");

  const [documentData, setDocumentData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchDocumentData = async () => {
      try {
        const collectionName = "people"; // Specify your collection name
        const documentId = uuid; // Replace with your document ID

        const peopleRef = collection(firestore, collectionName);
        const q = firestoreQuery(
          peopleRef,
          where("uIdInSystem", "==", documentId)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const data = querySnapshot.docs[0].data(); // Assuming you only need the first matching document
          setDocumentData(data);
        } else {
          console.error("No such document found!");
        }
      } catch (error) {
        console.error("Error fetching document data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDocumentData();
  }, [uuid]);

  return (
    <div>
      {loading ? (
        <p>Loading document data...</p>
      ) : documentData ? (
        <>
          {" "}
          <div style={divStyles.div}>
            <PDFDownloadLink
              style={divStyles.linkStyle}
              document={<MyDocument data={documentData} />}
              fileName="HealthCertificate.pdf"
            >
              {({ blob, url, loading: linkLoading, error }) =>
                linkLoading ? "جاري التحميل..." : "تنزيل الشهادة الصحية"
              }
            </PDFDownloadLink>
            <PDFViewer
              style={{
                width: "100vw",
                height: "100vh",
                overFlow: "hidden",
                marginTop: "30px",
              }}
            >
              <MyDocument data={documentData} />
            </PDFViewer>
          </div>{" "}
        </>
      ) : (
        <p>No document data available</p>
      )}
    </div>
  );
};

export default PdfComponent;
