
import './App.css';
import './licenses/License.css'
import RedirectToExternalSite from './Components/RedirectToExternalSite';

function App() {
  return (
    <div className="App">
  <RedirectToExternalSite />
    </div>
  );
}

export default App;

// eslint-disable-next-line no-lone-blocks
{/*
  PDF ready print ready website
  QR code generatiopn and uploaded with the person information
  Delete already added person
  Show all added people in one list 
  
  
  */ }