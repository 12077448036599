// MyDocument.js
import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { convertArabic } from "arabic-reshaper";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { firestore } from "../firebase-config"; // Adjust import as needed

import dinArabic from "./DroidKufi-Regular.ttf";
import noto from "./NotoKufiArabic-Regular.ttf";
import arial from "./ARIAL.ttf";
import albany from "./albanywtj.ttf";

import xImg from "../printPdf/1.png";
import qrBack from "../printPdf/qrBack.png";
import instructions from "../printPdf/HealthCertificateY.jpg";

Font.register({
  family: "Condo",
  src: dinArabic,
  fontStyle: "normal",
  fontWeight: "normal",
});
Font.register({
  family: "noto",
  src: noto,
  fontStyle: "normal",
  fontWeight: "normal",
});
Font.register({
  family: "arial",
  src: arial,
  fontStyle: "normal",
  fontWeight: "normal",
});
Font.register({
  family: "albany",
  src: albany,
  fontStyle: "normal",
  fontWeight: "normal",
});

function MyDocument({ data }) {
  const [documentData, setDocumentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const uuid = data.uIdInSystem;
  console.log(data);

  useEffect(() => {
    const fetchDocumentData = async () => {
      try {
        const collectionName = "people"; // Ensure this matches your Firestore collection
        const q = query(
          collection(firestore, collectionName),
          where("uIdInSystem", "==", uuid)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const documentsData = querySnapshot.docs.map((doc) => doc.data());
          function checkLanguage(inputString) {
            // Define a regular expression to detect Arabic characters
            const arabicRegex = /[\u0600-\u06FF]/;
            // Define a regular expression to detect English alphabet characters
            const englishRegex = /[A-Za-z]/;

            // Check for Arabic characters
            if (arabicRegex.test(inputString)) {
              return "noto";
            }
            // Check for English characters
            else if (englishRegex.test(inputString)) {
              return "arial";
            }
            // If neither, return Unknown
            else {
              return "Unknown";
            }
          }
          setDocumentData(checkLanguage(documentsData[0].name)); // Store all matching documents' data
        } else {
          console.error("No documents found with the provided uIdInSystem!");
        }
      } catch (error) {
        console.error("Error fetching document data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDocumentData();
  }, [uuid]);

  // Test the function
  // Output: Unknown
  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#E4E4E4",
      minHeight: "100vh",
      minWidth: "100vw",
    },
    body: {
      fontFamily: "Condo",
      fontWeight: 500,
    },
    img0: {
      position: "absolute",
      height: 515,
      width: 811.89,
    },
    img1: {
      width: 147.5,
      height: 145.5,
      marginTop: 115.5,
      marginLeft: 25,
    },
    img2: {
      position: "absolute",
      width: 72,
      height: 79,
      marginLeft: 559,
      marginTop: 22,
    },
    img3: {
      position: "absolute",
      width: 141.5,
      height: 141.5,
      marginTop: 301,
      marginLeft: 29,
    },
    img4: {
      position: "absolute",
      width: 106.25,
      height: 106.25,
      marginTop: 318.625,
      marginLeft: 46.25,
    },
    name: {
      color: "#0e726f",
      fontSize: "20px",
      position: "absolute",
      fontFamily: documentData,
      fontWeight: 700,
      marginTop: 132,
      marginLeft: 209,
      direction: "rtl",
      width: 588,
    },
    idNumber: {
      position: "absolute",
      marginTop: 211,
      fontFamily: "albany",
      marginLeft: 528,
      direction: "rtl",
      width: 260,
    },
    healthIdNumber: {
      position: "absolute",
      marginTop: 285,
      fontFamily: "albany",
      marginLeft: 528,
      width: 260,
      direction: "rtl",
    },
    healthCertificateIssueDate: {
      position: "absolute",
      marginTop: 355,
      marginLeft: 528,
      fontFamily: "albany",
      width: 260,
      direction: "rtl",
    },
    programType: {
      position: "absolute",
      marginTop: 422,
      marginLeft: 488,
      fontFamily: "Condo",
      fontWeight: 300,
      fontSize: "16px",
      width: 300,
      direction: "rtl",
    },
    nationality: {
      position: "absolute",
      marginTop: 208,
      marginLeft: 213,
      fontFamily: "Condo",
      fontWeight: 300,
      fontSize: "16px",
      width: 260,
      direction: "rtl",
    },
    occupation: {
      position: "absolute",
      marginTop: 281.58,
      marginLeft: 213,
      fontFamily: "Condo",
      fontWeight: 300,
      fontSize: "16px",
      width: 260,
      direction: "rtl",
    },
    healthCertificateExpireDate: {
      position: "absolute",
      fontFamily: "albany",
      marginTop: 355,
      marginLeft: 213,
      width: 260,
      direction: "rtl",
    },
    programTypeExpireDate: {
      position: "absolute",
      fontFamily: "albany",
      marginTop: 423,
      marginLeft: 213,
      width: 260,
      direction: "rtl",
    },
  });
  console.log(data.provinceImageURL);

  return (
    <Document language="TestLanguage" pageLayout="singlePage">
      <Page size={[515, 812]} orientation="landscape" style={styles.page}>
        <View>
          <Image src={xImg} style={styles.img0} />
          <Image src={data.imageUrl} style={styles.img1} />
          <Image src={data.provinceImageURL} style={styles.img2} />
          <Image src={qrBack} style={styles.img3} />
          <Image src={data.qrCodeImageUrl} style={styles.img4} />

          <Text style={styles.idNumber}>{data.idNumber}</Text>
          <Text style={styles.name} wrap={false}>
            {documentData === "noto" ? convertArabic(data.name) : data.name}
          </Text>
          <Text style={styles.healthIdNumber}>
            {data.healthCertificateNumber}
          </Text>
          <Text style={styles.healthCertificateIssueDate}>
            {data.healthLicenseIssueDateHijri}
          </Text>
          <Text style={styles.programType}>{data.programType}</Text>
          <Text style={styles.nationality}>{data.nationality}</Text>
          <Text style={styles.occupation}>{data.occupation}</Text>
          <Text style={styles.healthCertificateExpireDate}>
            {data.healthLicenseEndDateHijri}
          </Text>
          <Text style={styles.programTypeExpireDate}>
            {data.endDateOfProgram}
          </Text>
        </View>
      </Page>
      <Page size={[515, 812]} orientation="landscape" style={styles.page}>
        <Image src={instructions} style={styles.img0} />
      </Page>
    </Document>
  );
}

export default MyDocument;
