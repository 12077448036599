import { useState ,useRef,useEffect} from "react";
import MenuItemx from "./MenuItem";


export default function MenuComponent() {

    const [activeIndex, setActiveIndex] = useState(null);
    const navbarRef = useRef(null);
  
    const handleItemClick = (index) => {
      setActiveIndex(index === activeIndex ? null : index);
    };
  
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setActiveIndex(null);
      }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
      }, []);
    

 

    
    return (
        <ul className="menu" ref={navbarRef}>
        
       
            <li onClick={() => handleItemClick(0)}
                className="menu-item menu-item--expanded dropdown menu-item-first menu-level-0 menu-count-1">
                <MenuItemx   id={[1]} text={[<span>عن بلدي</span>]} div={[]}/>
                  <>

                    
                  </>
                <div className={activeIndex === 0 ? "dropdown-menu show " : "dropdown-menu" } >
                    <ul className="menu">
                        <li className="menu-item menu-item--expanded menu-level-1 menu-count-1">
                            <span>من نحن</span>
                            <ul className="menu">
                                <li className="menu-item menu-level-2 menu-count-1">
                                    <a
                                        href="https://balady.gov.sa/ar/about-balady"
                                        data-drupal-link-system-path="node/10671">
                                        من نحن
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-2">
                                    <a
                                        href="https://balady.gov.sa/ar/node/11036"
                                        data-drupal-link-system-path="node/11036">
                                        الهيكل التنظيمي
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-3">
                                    <a
                                        href="https://balady.gov.sa/ar/node/11038"
                                        data-drupal-link-system-path="node/11038">
                                        الهيكل الإستراتيجي للوزارة
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-4">
                                    <a
                                        href="https://balady.gov.sa/ar/node/11014"
                                        data-drupal-link-system-path="node/11014">
                                        السياسات والاستراتيجيات
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-5">
                                    <a
                                        href="https://balady.gov.sa/ar/node/11004"
                                        data-drupal-link-system-path="node/11004">
                                        أهداف التنمية المستدامة
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-6">
                                    <a
                                        href="https://balady.gov.sa/ar/partners"
                                        data-drupal-link-system-path="node/10903">
                                        الشركاء
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-7">
                                    <a
                                        href="https://balady.gov.sa/ar/node/10982"
                                        data-drupal-link-system-path="node/10982">
                                        الوظائف
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-8">
                                    <a
                                        href="https://balady.gov.sa/ar/node/11217"
                                        data-drupal-link-system-path="node/11217">
                                        تواصل معنا
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item menu-item--expanded menu-level-1 menu-count-2">
                            <a
                                href="https://balady.gov.sa/ar/e_participation"
                                data-drupal-link-system-path="node/11024">
                                المشاركة الإلكترونية
                            </a>
                            <ul className="menu">
                                <li className="menu-item menu-level-2 menu-count-1">
                                    <a
                                        href="https://balady.gov.sa/ar/e_participation/11215"
                                        data-drupal-link-system-path="node/11215">
                                        الاستشارات
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-2">
                                    <a
                                        href="https://balady.gov.sa/ar/e_participation/11190"
                                        data-drupal-link-system-path="node/11190">
                                        البيانات المفتوحة
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-3">
                                    <a
                                        href="https://balady.gov.sa/ar/e_participation/feedback"
                                        data-drupal-link-system-path="node/11205">
                                        التغذية الراجعة
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-4">
                                    <a
                                        href="https://balady.gov.sa/ar/e_participation/development-and-ideas"
                                        data-drupal-link-system-path="node/11281">
                                        التطوير المشترك والأفكار
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-5">
                                    <a
                                        href="https://balady.gov.sa/ar/e_participation/11022"
                                        data-drupal-link-system-path="node/11022">
                                        وسائل التواصل الاجتماعي
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item menu-item--expanded menu-level-1 menu-count-3">
                            <span>الأخبار والفعاليات</span>
                            <ul className="menu">
                                <li className="menu-item menu-level-2 menu-count-1">
                                    <a
                                        href="https://balady.gov.sa/ar/news"
                                        data-drupal-link-system-path="node/11152">
                                        الأخبار
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-2">
                                    <a
                                        href="https://balady.gov.sa/ar/events-list"
                                        data-drupal-link-system-path="events-list">
                                        الفعاليات
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item menu-item--expanded menu-level-1 menu-count-4">
                            <span>المنافسات والميزانية</span>
                            <ul className="menu">
                                <li className="menu-item menu-level-2 menu-count-1">
                                    <a
                                        href="https://balady.gov.sa/ar/node/10981"
                                        data-drupal-link-system-path="node/10981">
                                        المنافسات والمشتريات
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-2">
                                    <a
                                        href="https://balady.gov.sa/ar/node/11050"
                                        data-drupal-link-system-path="node/11050">
                                        الميزانية والإنفاق
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
             
               
            </li>
 
            <li onClick={() => handleItemClick(1)}
                className="menu-item menu-item--expanded dropdown menu-item-first menu-level-0 menu-count-2">
                
                <MenuItemx   id={[2]} text={[<span>مركز المعرفة</span>]} div={[]}/>
                 <div className={activeIndex === 1 ? "dropdown-menu show " : "dropdown-menu" }  >
                    
                    <ul className="menu">
                        <li className="menu-item menu-item--expanded menu-level-1 menu-count-1">
                            <span>مبادرات وشراكات</span>
                            <ul className="menu">
                                <li className="menu-item menu-level-2 menu-count-1">
                                    <a
                                        href="https://balady.gov.sa/ar/initiatives"
                                        data-drupal-link-system-path="node/10897">
                                        المبادرات
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-2">
                                    <a
                                        href="https://balady.gov.sa/ar/partners"
                                        data-drupal-link-system-path="node/10903">
                                        الشراكات
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-3">
                                    <a href="https://istitlaa.ncc.gov.sa/ar/Municipality/momra/Pages/default.aspx">
                                        منصة استطلاع
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-4">
                                    <a
                                        href="https://eparticipation.my.gov.sa/e-consultations/consultations/?title=&status=&type=&beneficiary=&sector=">
                                        منصة تفاعل
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item menu-item--expanded menu-level-1 menu-count-2">
                            <span>بيانات وإحصائيات</span>
                            <ul className="menu">
                                <li className="menu-item menu-level-2 menu-count-1">
                                    <a
                                        href="https://balady.gov.sa/ar/e_participation/11190"
                                        data-drupal-link-system-path="node/11190">
                                        البيانات المفتوحة
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-2">
                                    <a href="https://balady.gov.sa/ar/prints" data-drupal-link-system-path="prints">
                                        الوثائق والتقارير
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-3">
                                    <a
                                        href="https://balady.gov.sa/ar/e_participation/feedback/11180"
                                        data-drupal-link-system-path="node/11180">
                                        إحصائيات ومؤشرات المنصة
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
           
            </li>

            <li onClick={() => handleItemClick(2)}
                className="menu-item menu-item--expanded dropdown menu-item-first menu-level-0 menu-count-3">
               <MenuItemx text={[<span>الخدمات</span>]} div={[
                ]}/>
                <div className={activeIndex === 2 ? "dropdown-menu show " : "dropdown-menu" }>
                    <ul className="menu">
                        <li className="menu-item menu-item--expanded menu-level-1 menu-count-1">
                            <span>الصفحات الشخصية</span>
                            <ul className="menu">
                                <li className="menu-item menu-level-2 menu-count-1">
                                    <a href="https://balady.gov.sa/services/11106">
                                        إدارة الطلبات
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-2">
                                    <a href="https://balady.gov.sa/services/11108">
                                        إدارة الرخص
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-3">
                                    <a
                                        href="https://www.balady.gov.sa/ar/services/%D9%84%D9%88%D8%AD%D8%A9-%D8%A7%D9%84%D8%AA%D8%AD%D9%83%D9%85">
                                        لوحة التحكم
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item menu-item--expanded menu-level-1 menu-count-2">
                            <span>المنظمات والأنظمة</span>
                            <ul className="menu">
                                <li className="menu-item menu-level-2 menu-count-1">
                                    <a href="https://balady.gov.sa/services/10767">
                                        منصة رسم إشغال مرافق الإيواء
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-2">
                                    <a href="https://balady.gov.sa/services/10800">
                                        منصة رسم تقديم منتجات التبغ
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-3">
                                    <a
                                        href="https://balady.gov.sa/ar/products/10721"
                                        data-drupal-link-system-path="products/10721">
                                        بلدي أعمال
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-4">
                                    <a
                                        href="https://balady.gov.sa/ar/products/10635"
                                        data-drupal-link-system-path="products/10635">
                                        تصنيف مقدمي خدمات المدن
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item menu-item--expanded menu-level-1 menu-count-3">
                            <span>التفويض البلدي الإلكتروني</span>
                            <ul className="menu">
                                <li className="menu-item menu-level-2 menu-count-1">
                                    <a href="https://balady.gov.sa/services/10794">
                                        إضافة منشأة إلى مدير حساب
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-2">
                                    <a href="https://balady.gov.sa/services/10792">
                                        الاستعلام عن طلبات منشأة
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-3">
                                    <a href="https://balady.gov.sa/services/10793">
                                        الاستعلام عن مفوضي منشأة
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item menu-item--expanded menu-level-1 menu-count-4">
                            <span>الرخص التجارية</span>
                            <ul className="menu">
                                <li className="menu-item menu-level-2 menu-count-1">
                                    <a href="https://balady.gov.sa/services/11010">
                                        إصدار رخصة تجارية
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-2">
                                    <a href="https://balady.gov.sa/services/10485">
                                        تجديد رخصة نشاط تجاري
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-3">
                                    <a href="https://balady.gov.sa/services/10492">
                                        إلغاء رخصة نشاط تجاري
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item menu-item--expanded menu-level-1 menu-count-5">
                            <span>الرخص الإنشائية</span>
                            <ul className="menu">
                                <li className="menu-item menu-level-2 menu-count-1">
                                    <a href="https://balady.gov.sa/services/10472">
                                        إصدار رخصة بناء
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-2">
                                    <a href="https://balady.gov.sa/services/10538">
                                        خدمة إصدار رخصة تسوير أراضي فضاء
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item menu-item--expanded menu-level-1 menu-count-6">
                            <span>الشهادات الصحية</span>
                            <ul className="menu">
                                <li className="menu-item menu-level-2 menu-count-1">
                                    <a href="https://balady.gov.sa/services/10592">
                                        إصدار شهادة صحية
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-2">
                                    <a href="https://balady.gov.sa/services/10596">
                                        تجديد شهادة صحية
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item menu-item--expanded menu-level-1 menu-count-7">
                            <span>خدمات تنسيق المشروعات</span>
                            <ul className="menu">
                                <li className="menu-item menu-level-2 menu-count-1">
                                    <a
                                        href="https://balady.gov.sa/ar/products/10497"
                                        data-drupal-link-system-path="products/10497">
                                        خدمات تنسيق أعمال البنية التحتية
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-2">
                                    <a
                                        href="https://balady.gov.sa/ar/products/11535"
                                        data-drupal-link-system-path="products/11535">
                                        خدمات تنسيق المشروعات الكبرى
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item menu-item--expanded menu-level-1 menu-count-8">
                            <span>خدمات التقارير المساحية</span>
                            <ul className="menu">
                                <li className="menu-item menu-level-2 menu-count-1">
                                    <a href="https://balady.gov.sa/services/10747">
                                        إصدار تقرير مساحي
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item menu-item--expanded menu-level-1 menu-count-9">
                            <span>الهوية العقارية</span>
                            <ul className="menu">
                                <li className="menu-item menu-level-2 menu-count-1">
                                    <a href="https://balady.gov.sa/services/11580">
                                        ربط صك إلكتروني بالهوية العقارية
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item menu-level-1 menu-count-10">
                            <a
                                href="https://balady.gov.sa/ar/services"
                                data-drupal-link-system-path="node/10895">
                                قائمة الخدمات
                            </a>
                        </li>
                    </ul>
                </div>
                
                
              
                
            </li>


            <li onClick={() => handleItemClick(3)}
                className="menu-item menu-item--expanded dropdown menu-item-first menu-level-0 menu-count-4">
              
                <MenuItemx text={[<span>الاستعلامات</span>]} div={[  ]}/>
                
                    <div className={activeIndex === 3 ? "dropdown-menu show " : "dropdown-menu" }>
                    <ul className="menu">
                        <li className="menu-item menu-item--expanded menu-level-1 menu-count-1">
                            <span>الاستعلامات العامة</span>
                            <ul className="menu">
                                <li className="menu-item menu-level-2 menu-count-1">
                                    <a href="https://balady.gov.sa/services/11110">
                                        الاستعلام عن المخالفة للإجراءات الاحترازية
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-2">
                                    <a href="https://balady.gov.sa/services/11112">
                                        حاسبة الرسوم المعلوماتية
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-3">
                                    <a href="https://balady.gov.sa/services/11114">
                                        الاستعلام عن المكاتب الهندسية
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-4">
                                    <a href="https://balady.gov.sa/services/11116">
                                        الاستعلام عن عقود النظافة
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-5">
                                    <a href="https://balady.gov.sa/services/11118">
                                        أسواق المتاجر المتنقلة
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-6">
                                    <a href="https://balady.gov.sa/services/11120">
                                        الاستعلام عن الإيقافات
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-7">
                                    <a href="https://balady.gov.sa/services/11122">
                                        الاستعلام عن المخالفات
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item menu-item--expanded menu-level-1 menu-count-2">
                            <span>الأراضي والبناء</span>
                            <ul className="menu">
                                <li className="menu-item menu-level-2 menu-count-1">
                                    <a href="https://balady.gov.sa/services/11124">
                                        استعلام عن رخصة بناء
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-2">
                                    <a href="https://balady.gov.sa/services/11126">
                                        اشتراطات إيصال الخدمات الكهربائية
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-3">
                                    <a href="https://balady.gov.sa/services/11130">
                                        المستكشف الجغرافي
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-4">
                                    <a href="https://balady.gov.sa/services/11132">
                                        مستكشف التغطية لخدمات البنية التحتية
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-5">
                                    <a href="https://balady.gov.sa/services/11632">
                                        الاستعلام عن تقرير مساحي
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item menu-item--expanded menu-level-1 menu-count-3">
                            <span>الاستعلامات التجارية</span>
                            <ul className="menu">
                                <li className="menu-item menu-level-2 menu-count-1">
                                    <a href="https://new.balady.gov.sa/ar/services/11134">
                                        استعلام عن رخصة نشاط تجاري
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-2">
                                    <a href="https://balady.gov.sa/services/11136">
                                        الأنشطة التجارية والاشتراطات البلدية
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-3">
                                    <a href="https://balady.gov.sa/services/11138">
                                        الاستعلام عن مسارات العربات المتجولة
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item menu-item--expanded menu-level-1 menu-count-4">
                            <span>خدمات إكرام الموتى</span>
                            <ul className="menu">
                                <li className="menu-item menu-level-2 menu-count-1">
                                    <a href="https://balady.gov.sa/services/10488">
                                        الاستعلام عن مقدمي خدمات نقل وتجهيز الموتى (الجهات الخيرية)
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-2">
                                    <a href="https://balady.gov.sa/services/10724">
                                        الاستعلام عن قبر متوفي
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-3">
                                    <a href="https://balady.gov.sa/services/10477">
                                        طباعة شهادة دفن
                                    </a>
                                </li>
                                <li className="menu-item menu-level-2 menu-count-4">
                                    <a href="https://balady.gov.sa/services/10729">
                                        الاستعلام عن المقابر
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item menu-level-1 menu-count-5">
                            <a
                                href="https://balady.gov.sa/ar/products/11704"
                                data-drupal-link-system-path="products/11704">
                                الدليل التنظيمي للوحات التجارية
                            </a>
                        </li>
                    </ul>
                </div>
                
               
               
            </li>


            <li onClick={() => handleItemClick(4)}
                className="menu-item menu-item--expanded dropdown menu-item-first menu-level-0 menu-count-5">
                
                <MenuItemx text={[<span>المنصات</span>]} div={[
                     ]}/>
                    <div className={activeIndex === 4 ? "dropdown-menu show " : "dropdown-menu" }>
                    <ul className="menu">
                        <li className="menu-item menu-level-1 menu-count-1">
                            <a href="https://furas.momra.gov.sa/">
                                بوابة الفرص الاستثمارية
                            </a>
                        </li>
                        <li className="menu-item menu-level-1 menu-count-2">
                            <a href="https://engage.balady.gov.sa/">
                                المنصات التفاعلية
                            </a>
                        </li>
                    </ul>
                </div>
                
               
                
            </li>

            <li onClick={() => handleItemClick(5)}

                className="menu-item menu-item--expanded dropdown menu-item-first menu-level-0 menu-count-6">
               
               <MenuItemx text={[<span>تواصل معنا</span>]} div={[  ]}/> 
                
                <div className={activeIndex === 5 ? "dropdown-menu show " : "dropdown-menu" }>
                    <ul className="menu">
                        <li className="menu-item menu-level-1 menu-count-1">
                            <a
                                href="https://balady.gov.sa/ar/form/contact-us"
                                data-drupal-link-system-path="node/11316">
                                اتصل بنا
                            </a>
                        </li>
                        <li className="menu-item menu-level-1 menu-count-2">
                            <a
                                href="https://balady.gov.sa/ar/node/11211"
                                data-drupal-link-system-path="node/11211">
                                بلاغ عن فساد
                            </a>
                        </li>
                        <li className="menu-item menu-level-1 menu-count-3">
                            <a
                                href="https://balady.gov.sa/ar/faq"
                                data-drupal-link-system-path="node/10902">
                                الأسئلة الشائعة
                            </a>
                        </li>
                        <li className="menu-item menu-level-1 menu-count-4">
                            <a href="https://deaf.dga.gov.sa/">
                                الدعم الفني بلغة الإشارة
                            </a>
                        </li>
                        <li className="menu-item menu-level-1 menu-count-5">
                            <a
                                href="https://balady.gov.sa/ar/branches"
                                data-drupal-link-system-path="branches">
                                دليل الأمانات
                            </a>
                        </li>
                        <li className="menu-item menu-level-1 menu-count-6">
                            <a
                                href="https://balady.gov.sa/ar/e_participation/11022"
                                data-drupal-link-system-path="node/11022">
                                وسائل التواصل الإجتماعي
                            </a>
                        </li>
                        <li className="menu-item menu-level-1 menu-count-7">
                            <a href="https://balady.gov.sa/services/10541">
                                حجز موعد إلكتروني
                            </a>
                        </li>
                    </ul>
                </div>
                
              
                
            </li>
        </ul>
    )
}

