
export default function MenuItemx(props) {
  
    
    


    return ( <> <a
            className="dropdown-toggle"
            menuId={props.id}
            // onClick={()=>handleDropdownClick(props.id)}
            href="#"
            role="button"
            aria-expanded="false"
            data-toggle="dropdown">
            {props.text}
        </a>
        </>
    )
  }