import { firestore, storage, auth } from "../firebase-config";
import "../licenses/LicenseShower.css";
import React, { useState, useEffect, useRef } from "react";
import { QRCodeCanvas } from "qrcode.react";
import { uploadString } from "firebase/storage";
import { addDoc } from "firebase/firestore";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
  orderBy,
  query,
} from "firebase/firestore";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import Modal from "./Modal";

const PersonEditor = () => {
  const [people, setPeople] = useState([]);
  const [personData, setPersonData] = useState(null);
  const [docId, setDocId] = useState("");
  const [formError, setFormError] = useState("");
  const [formSuccess, setFormSuccess] = useState("");
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [editing, setEditing] = useState(false); // State to control edit fields visibility
  const qrRef = useRef();

  const alihsaaProvince =
    "https://firebasestorage.googleapis.com/v0/b/healthsite-f24cb.appspot.com/o/images%2FalihsaaProvinceLogo.png?alt=media&token=2ae0bae6-7824-4e19-8678-985b9b79ddf6";

  const alsharqiyaProvince =
    "https://firebasestorage.googleapis.com/v0/b/healthsite-f24cb.appspot.com/o/images%2FalsharqiyaProvinceLogo.png?alt=media&token=5c6e0e6a-e01b-4b90-b34d-291bd845f86d";

  const jiddaProvince =
    "https://firebasestorage.googleapis.com/v0/b/healthsite-f24cb.appspot.com/o/images%2FjiddaProvinceLogo.png?alt=media&token=ad78b14f-6eb3-4318-8f63-df581a633ea9";

  useEffect(() => {
    const q = query(collection(firestore, "people"), orderBy("name", "desc"));
    //getDocs(q)

    const fetchPeople = async () => {
      try {
        const querySnapshot = await getDocs(q);
        const peopleData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Sorting peopleData from oldest to newest
        peopleData.sort((a, b) => a.createdAt - b.createdAt);

        setPeople(peopleData);
      } catch (error) {
        console.error("Error fetching people:", error);
      }
    };

    fetchPeople();
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      console.log("User signed out.");
      window.location.href = "/login"; // Redirect to login page after signing out
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPersonData((prevData) => {
      const updatedState = { ...prevData, [name]: value };

      return updatedState;
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const deleteOldPhotos = async (imageUrls) => {
    if (imageUrls && imageUrls.length > 0) {
      try {
        const deletePromises = imageUrls.map(async (imageUrl) => {
          const imageRef = ref(storage, imageUrl);
          await deleteObject(imageRef);
        });
        await Promise.all(deletePromises);
      } catch (error) {
        console.error("Error deleting old images:", error);
      }
    }
  };

  const uploadFile = async (file) => {
    setUploading(true);
    setProgress(0);

    const storageRef = ref(storage, `images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(percent);
        },
        (err) => {
          setFormError(err.message);
          setUploading(false);
          reject(err);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setUploading(false);
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setFormError("");
    setFormSuccess("");

    try {
      let imageUrl = personData.imageUrl;

      let oldImages = [];
      if (imageUrl) oldImages.push(imageUrl);

      if (selectedFile) {
        await deleteOldPhotos(oldImages);
        imageUrl = await uploadFile(selectedFile);
      }
      const canvas = qrRef.current.querySelector("canvas");
      const qrImage = canvas.toDataURL("image/png");

      // Upload QR code image to Firebase Storage
      const qrStorageRef = ref(
        storage,
        `qrCodes/${personData.uIdInSystem}.png`
      );
      const qrUploadTask = await uploadString(
        qrStorageRef,
        qrImage,
        "data_url"
      );
      const qrCodeImageUrl = await getDownloadURL(qrUploadTask.ref);
      const updatedData = { ...personData, imageUrl, qrCodeImageUrl };
      const personRef = doc(firestore, "people", docId);
      await updateDoc(personRef, updatedData);
      setFormSuccess("Information updated successfully!");

      // Fetch updated people list
      const q = query(collection(firestore, "people"), orderBy("name", "desc"));

      const querySnapshot = await getDocs(q);
      const peopleData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Sorting peopleData from oldest to newest
      peopleData.sort((a, b) => a.createdAt - b.createdAt);

      setPeople(peopleData);
    } catch (error) {
      console.error("Error updating information:", error);
      setFormError("Failed to update information. Please try again.");
    }
  };

  const handleEdit = (person) => {
    setPersonData(person);
    setDocId(person.id);
    setEditing(true);
  };
  function handleAddQrCode(url) {
    setPersonData((prev) => {
      return {
        ...prev,
        qrCodeImageUrl: url,
      };
    });
  }
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleDelete = async (person) => {
    try {
      let oldImages = [];
      if (person.imageUrl) oldImages.push(person.imageUrl);
      if (person.qrCodeImageUrl) oldImages.push(person.qrCodeImageUrl); // Assuming QR code image URL field

      await deleteOldPhotos(oldImages);
      const personRef = doc(firestore, "people", person.id);
      await deleteDoc(personRef);
      setFormSuccess("Person deleted successfully!");

      // Fetch updated people list
      const q = query(collection(firestore, "people"), orderBy("name", "desc"));
      const querySnapshot = await getDocs(q);
      const peopleData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      peopleData.sort((a, b) => a.createdAt - b.createdAt);

      setPeople(peopleData);
    } catch (error) {
      console.error("Error deleting person:", error);
      setFormError("Failed to delete person. Please try again.");
    }
  };

  const handleDeleteClick = (person) => {
    setSelectedPerson(person);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedPerson(null);
  };

  const handleModalConfirm = () => {
    if (selectedPerson) {
      handleDelete(selectedPerson);
    }
    handleModalClose();
  };

  const closeModal = () => {
    setEditing(false);
    setPersonData(null);
    setDocId("");
    setSelectedFile(null);
    setFormError("");
    setFormSuccess("");
  };
  const option = useRef();
  function handleOrderSelect() {
    const order = option.current.value;
    if (order === "asc") {
      setPeople((prev) => {
        return [...prev].sort(
          (a, b) =>
            new Date(a.healthLicenseIssueDateMiladi) -
            new Date(b.healthLicenseIssueDateMiladi)
        );
      });
    }
    if (order === "des") {
      setPeople((prev) => {
        return [...prev].sort(
          (a, b) =>
            new Date(b.healthLicenseIssueDateMiladi) -
            new Date(a.healthLicenseIssueDateMiladi)
        );
      });
    }
  }

  return (
    <>
      <div className="editing-navigation-links">
        <a
          className="show-link nav-link"
          href="/management/Ehealth/admin/LicenseShower"
        >
          صفحة التعديل
        </a>
        <a
          className="show-link nav-link"
          href="/management/Ehealth/admin/createHealthLicense"
        >
          انشاء شهادة صحية
        </a>
        <button
          className="show-link"
          style={{ backgroundColor: "#bf2701" }}
          onClick={handleLogout}
        >
          تسجيل خروج
        </button>
      </div>

      <div className="editor-container">
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div className="select-container">
            <select
              className="select-element"
              ref={option}
              defaultValue="nothing"
              onChange={handleOrderSelect}
            >
              <option value="nothing" disabled>
                ترتيب حسب انشاء الشهادة الصحية
              </option>
              <option value="asc">ترتيب تصاعدي</option>
              <option value="des">ترتيب تنازلي</option>
            </select>
            <div className="select-arrow"></div>
          </div>
          <h3 style={{ alignSelf: "flex-start" }}>قائمة الأشخاص</h3>
        </div>
        {formError && <p className="error">{formError}</p>}
        {formSuccess && <p className="success">{formSuccess}</p>}

        <div style={listContainerStyle}>
          {people.map((person) => (
            <div key={person.id} style={listItemStyle}>
              <span>
                {person.name} ({person.nationality})
              </span>
              <div className="buttons">
                <button onClick={() => handleEdit(person)}>تحرير</button>
                <button
                  onClick={() => handleDeleteClick(person)}
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  حذف
                </button>
                <button style={{ color: "#fff", backgroundColor: "#3e027e" }}>
                  <a
                    href={`/Eservices/HealthIssue/PrintedLicenses/?uuid=${person.uIdInSystem}`}
                  >
                    {" "}
                    عرض{" "}
                  </a>
                </button>
                <button
                  style={{
                    color: "#fff",
                    fontSize: "14px",
                    backgroundColor: "#034541",
                  }}
                >
                  <a
                    href={`/management/Ehealth/admin/PrintableLicense/?uuid=${person.uIdInSystem}`}
                  >
                    تنزيل
                  </a>
                </button>
              </div>
            </div>
          ))}
          <Modal
            isOpen={isModalOpen}
            onClose={handleModalClose}
            onConfirm={handleModalConfirm}
            person={selectedPerson}
          />
        </div>
      </div>

      {editing && personData && (
        <div className="modal">
          <div className="modal-content">
            <form onSubmit={handleUpdate}>
              <h2>تعديل معلومات الشخص</h2>

              <div className="form-wrapper">
                <div className="form-group">
                  <label htmlFor="gov">الامانة</label>
                  <input
                    dir="rtl"
                    type="text"
                    id="gov"
                    name="gov"
                    value={personData.gov}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="provinceSelect">صورة الامانة</label>
                  <select
                    id="provinceSelect"
                    required
                    name={"provinceImageURL"}
                    defaultValue={personData.provinceImageURL}
                    onChange={handleInputChange}
                  >
                    <option disabled value={"Choose One"}>
                      اختر الامانة
                    </option>

                    <option value={alihsaaProvince}>امانة منطقة الاحساء</option>
                    <option value={alsharqiyaProvince}>
                      امانة منطقة الشرقية
                    </option>
                    <option value={jiddaProvince}>امانة جدة</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="governote">البلدية</label>
                  <input
                    dir="rtl"
                    type="text"
                    id="governote"
                    name="governote"
                    value={personData.governote}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="uIdInSystem">UID رقم الهوية في النظام</label>
                  <input
                    dir="rtl"
                    type="text"
                    id="uIdInSystem"
                    name="uIdInSystem"
                    value={personData.uIdInSystem}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="name">الاسم</label>
                  <input
                    dir="rtl"
                    type="text"
                    id="name"
                    name="name"
                    value={personData.name}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="licenceType">نوع الشهادة الصحية</label>
                  <select
                    name="licenceType"
                    type="radio"
                    defaultValue={personData.licenceType}
                    onChange={handleInputChange}
                  >
                    <option value="شهادة صحية سنوية">شهادة صحية سنوية</option>
                    <option value="الشهادة الصحية الموحدة">
                      الشهادة الصحية الموحدة
                    </option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="idNumber">رقم الهوية</label>
                  <input
                    dir="rtl"
                    type="text"
                    id="idNumber"
                    name="idNumber"
                    value={personData.idNumber}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="sex">الجنس</label>
                  <select
                    name="sex"
                    type="radio"
                    defaultValue={personData.sex}
                    onChange={handleInputChange}
                  >
                    <option value="ذكر">ذكر</option>
                    <option value="انثى">انثى</option>
                  </select>
                </div>

                <div className="input-group"></div>
                <div className="form-group">
                  <label htmlFor="nationality">الجنسية</label>
                  <input
                    dir="rtl"
                    type="text"
                    id="nationality"
                    name="nationality"
                    value={personData.nationality}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="healthCertificateNumber">
                    رقم الشهادة الصحية
                  </label>
                  <input
                    dir="rtl"
                    type="text"
                    id="healthCertificateNumber"
                    name="healthCertificateNumber"
                    value={personData.healthCertificateNumber}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="healthLicenseIssueDateHijri">
                    تاريخ اصدار الشهادة الصحية هجري
                  </label>
                  <input
                    dir="rtl"
                    name="healthLicenseIssueDateHijri"
                    type="text"
                    value={personData.healthLicenseIssueDateHijri}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="healthLicenseIssueDateMiladi">
                    تاريخ اصدار الشهادة الصحية ميلادي
                  </label>
                  <input
                    dir="rtl"
                    name="healthLicenseIssueDateMiladi"
                    type="text"
                    value={personData.healthLicenseIssueDateMiladi}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="healthLicenseEndDateHijri">
                    تاريخ نهاية الشهادة الصحية هجري
                  </label>
                  <input
                    dir="rtl"
                    name="healthLicenseEndDateHijri"
                    type="text"
                    value={personData.healthLicenseEndDateHijri}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="healthLicenseEndDateMiladi">
                    تاريخ نهاية الشهادة الصحية ميلادي
                  </label>
                  <input
                    dir="rtl"
                    name="healthLicenseEndDateMiladi"
                    type="text"
                    value={personData.healthLicenseEndDateMiladi}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="programType">نوع البرنامج التثقيفي</label>
                  <input
                    dir="rtl"
                    name="programType"
                    type="text"
                    value={personData.programType}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="endDateOfProgram">
                    تاريخ انتهاء البرنامج التثقيفي
                  </label>
                  <input
                    dir="rtl"
                    name="endDateOfProgram"
                    type="text"
                    value={personData.endDateOfProgram}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="programLicenseId">رقم الرخصة</label>
                  <input
                    dir="rtl"
                    name="programLicenseId"
                    type="text"
                    value={personData.programLicenseId}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="facilityName">اسم المنشآة</label>
                  <input
                    dir="rtl"
                    name="facilityName"
                    type="text"
                    value={personData.facilityName}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="occupation">المهنة</label>
                  <input
                    dir="rtl"
                    name="occupation"
                    type="text"
                    value={personData.occupation}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="facilityNumber">رقم المنشأة</label>
                  <input
                    dir="rtl"
                    name="facilityNumber"
                    type="text"
                    value={personData.facilityNumber}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="photo">تغيير الصورة</label>
                  <input
                    dir="rtl"
                    type="file"
                    id="photo"
                    onChange={handleFileChange}
                  />
                  {uploading && <p>جاري التحميل... {progress}%</p>}
                  {personData.imageUrl && (
                    <img
                      src={personData.imageUrl}
                      alt="Uploaded"
                      style={{ width: "100px", height: "auto" }}
                    />
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="photo">QR CODE</label>
                  {personData.qrCodeImageUrl === undefined ? (
                    <button
                      onClick={() =>
                        handleAddQrCode(
                          `https://baledyapp.com/Eservices/HealthIssue/PrintedLicenses/?uuid=${personData.uIdInSystem}`
                        )
                      }
                    >
                      انشاء Qr code
                    </button>
                  ) : (
                    <div ref={qrRef}>
                      <QRCodeCanvas
                        value={`https://baledyapp.com/Eservices/HealthIssue/PrintedLicenses/?uuid=${personData.uIdInSystem}`}
                        size={128}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="form-actions">
                <button type="submit" className="btn btn-primary">
                  حفظ التعديلات
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => closeModal()}
                >
                  إلغاء
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <style jsx="true">{`
        .modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .buttons > button {
          font-weight: bold;

          margin-inline: 6px;
        }
        .buttons > button > a {
          color: #fff;
          font-weight: bold;
          text-decoration: none;
          font-size: 14px;
        }
        .modal-content {
          background: #fff;
          padding: 20px;
          border-radius: 10px;
          max-width: 80%;
          width: 500px;
          max-height: 80%;
          overflow: auto;
        }

        .form-wrapper {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }

        .form-group {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }

        .form-actions {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
        }

        .btn {
          padding: 10px 15px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }

        .btn-primary {
          background-color: #007bff;
          color: white;
          font-weight: bold;
        }

        .btn-secondary {
          background-color: #6c757d;
          color: white;
          font-weight: bold;
        }

        .editing-navigation-links {
          display: flex;
          justify-content: space-around;
          margin-bottom: 20px;
        }

        .editor-container {
          margin-bottom: 20px;
        }
      `}</style>
    </>
  );
};

// CSS-in-JS style objects for vertical list layout
const listContainerStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  padding: "20px",
  backgroundColor: "#f0f0f0",
  borderRadius: "10px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
};

const listItemStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px",
  direction: "rtl",
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
};

export default PersonEditor;
